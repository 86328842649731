@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
}

a  {
  cursor: pointer;
  text-decoration: none;
}

.Footer {
  background-color: #1C212A;
  color: #ffffff73;
}
.Footer svg {
  color: #00c254
}
.Footer h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ffffff;
}
.Footer a {
  color: #ffffffb3;
  transition: all .3s;
}
.Footer a:hover {
  color: #00c254;
}
.Footer .FooterContent {
  padding: 50px 0;
}

.Footer .FooterOurServices .SocialMediaIcon a:hover {
  opacity: 0.7;
  transform: scale(1.1);
}
.Footer .FooterOurServices .SocialMediaIcon {
  display: flex;
  gap: 20px;
}
.Footer .FooterOurServices ul li,
.Footer .FooterContact ul li {
  margin-bottom: 8px;
}

.Footer .FooterContact ul li span {
  margin-left: 10px;
}

.Footer .Copyright {
  background-color: #272F3A;
  height: 65px;
  color: #ffffff99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: center;
}
.main-section {
  gap: 110px;
}
.DescriptionText {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
}

.NossasFerramentasSection {
  background-color: #F4F8FE;
}

.NossasFerramentasSection h2{
  font-weight: 700;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 30px;
}

.Cards .CardItem .CardContent a,
.Cards .CardItem .CardContent h5 {
  margin-top: 25px;
  font-weight: 700;
}
.Cards .CardItem .CardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 25px;
  padding: 38px;
  min-height: 100%;
  border: solid 1px #00000015;
  transition: 0.3s;
}

.Cards .CardItem .CardContent:hover {
  box-shadow: 0px 10px 50px -30px #00000075;
  transform: translateY(-2px);
}
.Cards .CardItem {
  padding: 24px;
}

.CardContent a {
  font-weight: 600;
  transition: opacity 0.2s;
}
.CardContent a:hover {
  opacity: 0.8;
}

.Header .ContainerTitle {
  margin-top: 100px;
  margin-bottom: 100px;
}

.MainTitle span {
  font-weight: 600;
  color: #00000087;
  font-size: 1.2em;
}
.MainTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px
}

.MainButtons {
  display: flex;
  justify-content: flex-start;
  gap: 22px;
}

.Body .container {
  padding-bottom: 90px;
}
.Body > .container {
  margin-top: 150px;
}

.Body .container .h2 {
  font-size: 3rem;
  font-weight: 700;
}

.CriarContaSection{
  background-image: url('../images/maquininha-cartao-agriswap.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
}
.CriarContaSection .container {
  height: 400px;
}
.CriarContaSection h2 {
  font-weight: 700;
}

@media only screen and (min-width: 769px) {
  .Footer .Copyright br {
      display: none;
  }
}

